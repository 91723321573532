import {Spec, SwaggerUIBundle} from 'swagger-ui-dist'
import jsonSpec from './spec.json';

export const initSwagger = async (): Promise<void> => {
    const spec = jsonSpec as Spec;
    renderUI(spec);
};

const renderUI = (spec?: Spec): void => {
    SwaggerUIBundle({
        spec: spec,
        'dom_id': '#swagger',
        deepLinking: true,
    });
};
